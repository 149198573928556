export const SEMESTERS = [
	{
		label: "First",
		value: "First"
	},
	{
		label: "Second",
		value: "Second"
	}
];

export const SCHOOL_DETAILS = {
	name: "Federal Polytechnic Ohodo",
	location: "Ohodo",
	pmb: "PCW6+M8J, 411108, Ohodo Abba, Enugu State, Nigeria",
	shortForm: "FEDPOD"
};

export const PAYMENTTYPES = [
	{
		label: "Full Payment",
		value: "Full"
	},
	{
		label: "First Installment",
		value: "FirstInstallment"
	},
	{
		label: "Second Installment",
		value: "SecondInstallment"
	}
];

export const PAYMENTIDENTIFIER = {
	schoolFees: 1,
	acceptance: 2,
	hostel: 8,
	sundry: 9
};

export const PAGESIZE = {
	sm: 5,
	md: 10,
	lg: 15,
	xl: 20
};

export const SEARCH_DELAY = {
	sm: 300,
	md: 500,
	lg: 800,
	xl: 1000
};

export const INITIAL_DATE = "0001-01-01T00:00:00";

export const MINIMUM_AGE = 15;
export const MAXIMUM_AGE = 80;
export const TENECE_SUPPORT_URL = "https://teneceschoolsupport.com/";

// Authentication Constants
export const TOKEN_HOLDER = `${SCHOOL_DETAILS.shortForm}token`;
export const USER_ROLE_HOLDER = `${SCHOOL_DETAILS.shortForm}userRole`;
export const USER_NAME_HOLDER = `${SCHOOL_DETAILS.shortForm}userName`;
export const BIRTHDAY_STATE_HOLDER = `${SCHOOL_DETAILS.shortForm}birthday`;
export const STUDENT_TYPE_HOLDER = `${SCHOOL_DETAILS.shortForm}studentType`;
export const USER_TYPES = ["lecturer", "student", "official"];
