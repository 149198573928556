const baseUrl = "Enum";

export const getAdmissionTypesUrl = () => `${baseUrl}/student-admisssion-type`;
export const getBloodGroupsUrl = () => `${baseUrl}/BloodGroups`;
export const getGenoTypesUrl = () => `${baseUrl}/GenoTypes`;
export const getMedicalRecordsUrl = () => `${baseUrl}/MedicalRecords`;
export const getRelationshipsUrl = () => `${baseUrl}/Relationships`;
export const getSponsorRelationshipsUrl = () =>
	`${baseUrl}/sponsor-relationship`;
export const getReligionsUrl = () => `${baseUrl}/Religions`;
export const getStudentModesUrl = () => `${baseUrl}/student-modes`;
export const getStudentModesOfStudyUrl = () =>
	`${baseUrl}/student-mode-of-study`;
export const getStudentModeOfEntryUrl = () =>
	`${baseUrl}/student-mode-of-entry`;
export const getEyeColorsUrl = () => `${baseUrl}/eye-color`
export const getCourseTypes = () => `${baseUrl}/coursetype`;
export const getUnitLoads = () => `${baseUrl}/unit-load`;
export const getSchoolFeesPaymentTypesUrl = () => `${baseUrl}/payment-types`;
export const getGendersUrl = () => `${baseUrl}/Genders`;
export const getMaritalStatusesUrl = () => `${baseUrl}/MaritalStatus`;
export const getPGStudentTypesUrl = () => `${baseUrl}/pg-student-types`;
export const getPGYearOfStudyUrl = () => `${baseUrl}/pg-year-of-study`;
export const getOlevelGradeUrl = () => `${baseUrl}/O-level-Grade`;
export const getOlevelCertificateTypesUrl = () =>
	`${baseUrl}/Upload-Document-Types`;
export const getUserTypesUrl = () => `${baseUrl}/user-types`;
export const getGroupSelectionsUrl = () => `${baseUrl}/group-selection`;
export const getPaymentPurposeUrl = () => `${baseUrl}/payment-purpose`;
export const getHostelActivationStatusUrl = () =>
	`${baseUrl}/HostelActivationStatus`;
export const getSundryPaymentPurposesUrl = () =>
	`${baseUrl}/sundry-payment-purposes`;
export const getSundryPaymentPurposeUrl = () =>
	`${baseUrl}/sundry-payment-purposes`;
